@use 'custom-properties';

// base/type

body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
label,
legend,
button,
input,
select,
textarea,
li,
a,
strong,
span,
th,
caption,
td,
p,
footer,
small {
	color: rgba(var(--font-dark), 1);
	font-family: 'Aktiv Grotesk', sans-serif;
}

body {
	background-color: rgba(var(--base-03), 1);

	&.access-blocked {
		background-color: rgba(var(--primary-accent-white), 1);

		h2 {
			color: rgba(var(--font-dark), 1);
			font: var(--h2);
		}

		p {
			color: rgba(var(--font-supportive), 1);
		}

		a {
			color: rgba(var(--primary-accent-red), 1);
		}
	}
}

small {
	color: rgba(var(--font-supportive), 1);
}

// components/accordion

.accordion {
	.accordion__link {
		&::before,
		&::after {
			background-color: rgba(var(--primary-accent-red), 1);
		}
	}
}

// components/bonus-active

.menu {
	&.activity-item-list-container {
		.page-head {
			.page-head__title {
				color: rgba(var(--base-11), 1);
			}
		}

		.activity-bg {
			background-color: rgba(var(--base-01), 1);
		}
	}
}

.bonus-active {
	.u-box-bg {
		background-color: rgba(var(--base-02), 1);
	}

	.bonus-active__image-wrapper {
		background-color: rgba(var(--base-02), 1);
	}
}

.progress {
	svg {
		path {
			stroke: rgba(var(--base-01), 1);
		}

		.progress__meter {
			stroke: rgba(var(--primary-accent-red), 1);
		}
	}

	.progress__number {
		color: rgba(var(--base-02), 1);

		span {
			color: rgba(var(--base-02), 1);
		}
	}
}

// components/buttons

.btn {
	background-color: rgba(var(--primary-accent-red), 1);
	color: rgba(var(--primary-accent-yellow), 1);

	&:disabled {
		background-color: rgba(var(--base-09), 1);
		color: rgba(var(--base-06), 1);
	}

	&.btn--secondary {
		background-color: rgba(var(--base-10), 1);
		color: rgba(var(--base-02), 1);
	}

	&.btn--secondary.js-support-button {
		background-color: rgba(var(--primary-accent-grey-light), 1);
		color: rgba(var(--font-dark), 1);
	}

	&.btn--tertiary {
		background-color: rgba(var(--base-03), 1);
		color: rgba(var(--base-11), 1);
	}

	&.btn--clear,
	&.btn--login,
	&.btn--signup,
	&.btn--skip {
		color: rgba(var(--primary-accent-red), 1);
	}

	&.btn--applepay {
		-apple-pay-button-style: rgba(var(--base-12), 1);
	}

	&.btn--round,
	&.btn--ghost {
		color: rgba(var(--base-02), 1);
	}

	&.btn--light {
		background-color: rgba(var(--base-03), 1);
		color: rgba(var(--base-11), 1);
	}

	&.btn--inverted {
		color: rgba(var(--primary-accent-yellow), 1);
	}

	&.btn--visit {
		background-color: rgba(var(--base-10), 1);
		color: rgba(var(--base-02), 1);
	}

	&.btn--action-screen-confirm {
		background-color: rgba(var(--primary-accent-red), 1);
		color: rgba(var(--font-brand-02), 1);
	}

	&.btn--responsible-gaming-settings {
		background-color: rgba(var(--primary-accent-red), 1);
	}

	&.btn--responsible-gaming-logout {
		background-color: transparent;
		color: rgba(var(--primary-accent-red), 1);
	}

	&.btn--transaction-history-reality-check,
	&.btn--disable-reality-check {
		background-color: transparent;
		border: solid 1px rgba(var(--primary-accent-green), 1);
		color: rgba(var(--primary-accent-green), 1);
	}
}

.call-support-btn {
	background-color: rgba(var(--primary-accent-red), 1);
}

// components/campaign

// components/collapse

.welcome-menu {
	.welcome-screen-collapse {
		background-color: rgba(var(--primary-accent-red), 1);

		p,
		span {
			color: rgba(var(--base-01), 1);
		}

		.collapse__continue-icon {
			fill: rgba(var(--base-02), 1);
		}

		tr:not(:last-of-type) {
			border-bottom: 1px solid rgba(var(--primary-accent-red-dark), 1);
		}
	}
}

// components/detail-accordion

.details-accordion {
	.summary-icon::after {
		filter: brightness(14%) sepia(1) hue-rotate(4deg) saturate(40);
	}
}

// components/filters

.log-in__form,
.user-options {
	background-color: rgba(var(--base-01), 1);
	border-bottom: 1px solid rgba(var(--base-03), 1);
	color: rgba(var(--base-11), 1);

	label {
		color: rgba(var(--base-11), 1);
	}
}

.filters {
	input {
		&:checked {
			+ .filters__filter {
				color: rgba(var(--primary-accent-red), 1);
			}
		}
	}

	.sliding-border {
		background-color: rgba(var(--primary-accent-red), 1);
		height: 2px;
	}
}

.shop-product-filters {
	a {
		&::after {
			background-color: var(--filter-bar-inactive);
			bottom: 0;
			content: '';
			height: 1px;
			left: 0;
			position: absolute;
			width: 100%;
		}
	}
}

// components/flags
// components/form

:-webkit-autofill,
:-webkit-autofill:hover,
:-webkit-autofill:focus {
	box-shadow: inset 0 0 0 9999px #fff !important;
	-webkit-text-fill-color: #333 !important;
}

.input-wrap {
	.input-wrap__input {
		border-bottom: 1px solid rgba(var(--base-05), 1);
		color: rgba(var(--base-11), 1);
	}

	&::after {
		background-color: rgba(var(--base-12), 1);
	}

	.slider-input {
		background-color: rgba(var(--base-03), 1);
	}
}

.legend,
.input-wrap__label {
	color: rgba(var(--base-05), 1);
}

::-webkit-input-placeholder {
	// color: rgba(var(--base-06), 1);
	color: rgba(var(--font-supportive), 1);
}

::-moz-placeholder {
	// color: rgba(var(--base-06), 1);
	color: rgba(var(--font-supportive), 1);
}

:-ms-input-placeholder {
	// color: rgba(var(--base-06), 1);
	color: rgba(var(--font-supportive), 1);
}

// New checkboxes

.custom-checkbox-list {
	li {
		border-bottom: 1px solid rgba(var(--base-05), 1);
	}
}

.toggle-password {
	svg {
		fill: rgba(var(--font-dark), 1);
	}
}

// components/game-category
// components/game-item
// components/game-list

.item__footer {
	border-top: 1px solid rgba(var(--base-03), 1);

	.item__love {
		border-right: 1px solid rgba(var(--base-03), 1);

		.item__love-icon {
			fill: rgba(var(--base-11), 1);
		}

		&.game-item__love--selected {
			svg.item__love-icon {
				fill: rgba(var(--primary-accent-red), 1);
			}

			.item__love-counter {
				color: rgba(var(--base-11), 1);
			}
		}
	}

	.item__love-counter,
	.item__provider-title {
		color: rgba(var(--base-11), 1);
	}
}

.item__love.game-item__love--selected {
	svg.item__love-icon {
		fill: rgba(var(--primary-accent-yellow), 1);
	}

	.item__love-counter {
		color: rgba(var(--primary-accent-yellow), 1);
	}
}

.item__live-casino {
	border-bottom: 1px solid rgba(var(--base-03), 1);
	color: rgba(var(--base-09), 1);

	.last-winning-number-container {
		.number-to-appear-text,
		.number-to-disappear-text {
			color: rgba(var(--primary-accent-grey-light), 1);
			font: var(--caption);
		}
	}
}

.game-item__jackpot {
	background-color: rgba(var(--base-03), 1);
	color: rgba(var(--base-11), 1);
}

// components/header

.page-head {
	background-color: rgba(var(--primary-accent-red), 1);

	.page-head--bankid-email {
		.page-head__back {
			fill: rgba(var(--base-11), 1);
		}
	}

	.btn--skip,
	.btn--login,
	.page-head__title {
		color: rgba(var(--primary-accent-yellow), 1);
	}

	.svg-logo {
		fill: rgba(var(--primary-accent-yellow), 1);
	}

	svg {
		fill: rgba(var(--base-01), 1);
	}

	.page-head__back.u-show {
		svg {
			fill: rgba(var(--base-11), 1);
		}
	}

	&.u-bg-trans {
		.page-head__title {
			color: rgba(var(--base-11), 1);
		}

		.page-head__back {
			.icon--sprite {
				fill: rgba(var(--base-11), 1);
			}
		}

		svg {
			fill: rgba(var(--base-11), 1);
		}

		.btn--skip,
		.btn--login {
			color: rgba(var(--primary-accent-red), 1);
		}

		&.page-head-welcome,
		&.activity-header {
			svg {
				fill: rgba(var(--base-01), 1);
			}
		}
	}

	&.country-select-header {
		.page-head__title {
			color: rgba(var(--base-11), 1);
		}

		svg {
			fill: rgba(var(--base-11), 1);
		}
	}
}

.welcome-icon_welcomepage {
	fill: rgba(var(--primary-accent-red), 1);
}

// components/link-list

.link-list {
	.link-list__item {
		background-color: rgba(var(--base-10), 1);

		&--rounded {
			border-radius: 3px;
		}

		&.disabled {
			svg {
				fill: rgba(var(--base-09), 1);
			}

			.menu__main-title,
			.link-list__preamble,
			.link-list__continue-icon {
				color: rgba(var(--base-09), 1);
			}
		}

		svg {
			fill: rgba(var(--base-02), 1);
		}

		.menu__main-title,
		.link-list__preamble {
			color: rgba(var(--base-02), 1);
		}

		.link-list__continue-icon {
			fill: rgba(var(--base-02), 1);
		}

		.link-list__value {
			color: rgba(var(--base-06), 1);
		}

		.link-list__status-verified {
			color: rgba(var(--base-02), 1);
		}

		.link-list__status-message {
			color: rgba(var(--font-supportive), 1);
		}

		.link-list__status-unverified {
			color: rgba(var(--base-06), 1);
		}
	}

	&.link-list--flip-colors {
		.link-list__item {
			background-color: rgba(var(--base-03), 1);

			svg {
				fill: rgba(var(--base-11), 1);
			}

			.link-list__item-link {
				span {
					color: rgba(var(--base-11), 1);
				}
			}

			.link-list__value {
				color: rgba(var(--base-06), 1);
			}

			.link-list__more-icon {
				height: 20px;
				width: 20px;
			}
		}
	}
}

.account-settings-menu {
	.link-list .link-list__item {
		background-color: rgba(var(--base-02), 1);

		.menu__main-title,
		.link-list__preamble,
		.link-list__continue-icon {
			color: var(--main-title);
		}

		.link-list__continue-icon {
			fill: rgba(var(--base-11), 1);
		}
	}
}

.sportsbook-relieve-limits-dialog {
	h2 {
		color: var(--main-title);
	}

	.link-list.link-list--flip-colors {
		.link-list__item svg {
			fill: rgba(var(--primary-accent-red), 1);
		}
	}
}

// components/list-options

.list-options {
	background-color: rgba(var(--base-01), 1);

	.link-list {
		.link-list__item {
			background-color: transparent;
			border-bottom: 1px solid rgba(var(--base-05), 1);
		}
	}
}

// components/my notifications

.my-notifications-wrapper .page-head {
	svg {
		fill: rgba(var(--base-11), 1);
	}
}

// components/notify

.notification-wrap {
	box-shadow: 0 0 4px black;
	color: rgba(var(--base-02), 1);

	.page-head__close {
		svg {
			fill: #fff;
		}
	}

	p,
	.notification-wrap__error-text-msg {
		color: rgba(var(--base-02), 1);
	}

	.notification-wrap__error-text-header {
		color: rgba(var(--base-02), 1);
	}

	.header-icon {
		svg {
			fill: #fff;
		}
	}

	&.red {
		background-color: rgba(var(--error), 1);
	}

	&.green {
		background-color: rgba(var(--success), 1);
	}

	&.neutral {
		background-color: rgba(var(--info), 1);
	}
}

//notification - inline
.notification-inline-wrap {
	&.red {
		background-color: rgba(var(--error), 1);
	}

	&.green {
		background-color: rgba(var(--success), 1);
	}

	&.neutral {
		background-color: rgba(var(--info), 1);
	}
}
// BINARY QUESTION MODAL

.binary-question {
	.binary-question__inner {
		background-color: rgba(var(--primary-accent-white), 1);
	}

	.binary-question__button-container {
		.binary-question__yes,
		.binary-question__no,
		.binary-question__third {
			background: none;
		}
	}
}

// NOTIFY

.notify {
	&.notify--is-open {
		section {
			svg {
				fill: rgba(var(--base-02), 1);
			}

			h2,
			p {
				color: rgba(var(--base-02), 1);
			}
		}
	}

	&.notify--success {
		background-color: rgba(var(--success), 1) !important;
	}

	&.notify--failure {
		background-color: rgba(var(--error), 1) !important;
	}
}

.notify__head {
	.notify__head--quick-deposit {
		fill: var(--section-head-main-icon);
	}
}

// PROGRESS BAR

.notify__progress-bar {
	background-color: rgba(var(--base-11), 1);

	&.notify__progress-bar--fill-success {
		background-color: rgba(var(--success), 1);
		background-image: none;
	}

	&.notify__progress-bar--fill-fail {
		background-color: rgba(var(--error), 1);
		background-image: none;
	}
}

// OPTION BOX

// OVERLAY BEHIND MODAL

.deposit-siru,
.binary-question {
	&.notify--is-open {
		background-color: rgba(var(--base-11), 0.85);
	}
}

// COOKIE CONSENT

// PAYMENT METHODS

.payment-methods {
	&__item {
		background-color: rgba(var(--primary-accent-grey-light), 1);

		&__logo {
			svg.logo-payment-method {
				background-color: #fff;
			}
		}
	}
}

.js-notify__button-wrapper--failed {
	svg {
		fill: rgba(var(--font-dark), 1);
	}
}

.payment-reminder {
	color: rgba(var(--base-11));
	padding: 6px 0;
	white-space: pre-line;
}

// Two Factor auth on execute deposit

.two-fact {
	&__error-label {
		color: rgba(var(--error), 1);
	}

	.btn:disabled {
		background-color: inherit;
	}
}

.notify--is-open {
	background-color: rgba(var(--base-11), 1);

	&.reality-check-confirm {
		background-color: rgba(var(--primary-accent-white), 1) !important;
		background-image: none;

		.page-head__back {
			svg {
				fill: rgba(var(--base-11), 1);
			}
		}
	}
}

.option-box {
	background-color: rgba(var(--base-01), 1);

	.option-box__inner {
		border-bottom: 1px solid rgba(var(--base-05), 1);
	}

	.btn--secondary {
		background-color: rgba(var(--base-01), 1);
		color: rgba(var(--base-11), 1);
	}
}

.binary-question {
	.binary-question__inner {
		background-color: rgba(var(--base-01), 1);

		.binary-question__main-title {
			color: rgba(var(--base-11), 1);
		}
	}

	.binary-question__p,
	.input-wrap__input,
	.two-fact__input-wrap input {
		color: rgba(var(--base-11), 1);
	}

	.input-wrap__input,
	.two-fact__input-wrap input {
		background-color: rgba(var(--base-04), 1);
	}

	.binary-question__yes,
	.binary-question__no,
	.binary-question__third {
		border-top: 1px solid rgba(var(--base-05), 0.5);
		color: rgba(var(--base-11), 1);
	}

	.binary-question__yes {
		border-left: 1px solid rgba(var(--base-05), 0.5);
		border-top: 1px solid rgba(var(--base-05), 0.5);
	}
}

// components/quick-deposit

.quick-deposit-wrapper {
	background-color: rgba(var(--base-01), 1);

	* {
		color: var(--main-title);
	}

	.btn {
		color: rgba(var(--primary-accent-yellow), 1);
	}

	.page-head__back {
		fill: rgba(var(--base-10), 1);
	}

	.page-head__title {
		color: rgba(var(--base-10), 1);
	}
}

// components/scroll-page-head

header.page-head {
	&.page-head--opac {
		background-color: rgba(var(--primary-accent-red), 1);

		.btn--skip,
		.btn--login,
		.page-head__title {
			color: rgba(var(--base-02), 1);
		}

		.icon--sprite {
			fill: rgba(var(--base-02), 1);
		}
	}
}
// components/select

.custom-select {
	background-color: rgba(var(--base-02), 1);
	border-bottom: 1px solid rgba(var(--base-05), 1);

	select {
		color: rgba(var(--base-11), 1);
	}

	select.placeholder-active {
		color: rgba(var(--base-06), 1);
	}

	&::after {
		filter: brightness(0) saturate(100%) invert(6%) sepia(0%) saturate(0%) hue-rotate(148deg) brightness(99%)
			contrast(84%);
	}
}

// components/spinner
// components/support-nav

.dots-container {
	svg {
		fill: rgba(var(--primary-accent-red), 1);
	}
}

.support-nav {
	&.support-nav--is-open {
		background-color: rgba(var(--opacity-overlay), 0.85);
	}

	.support-nav__head {
		h2,
		h3 {
			color: rgba(var(--font-dark), 1);
		}
	}

	.btn--secondary {
		background-color: rgba(var(--primary-accent-white), 1);
		color: rgba(var(--font-dark), 1);
	}
}

// components/proposed-locale-nav

.propose-locale-nav {
	.propose-locale-wrapper {
		border-radius: 3px;

		.btn--secondary {
			border-bottom: 0;
			border-top: 1px solid rgba(var(--propose-locale-border, var(--base-07)), 1);
			color: rgba(var(--font-dark), 1);
		}
	}
}

// components/tables

td {
	border-bottom: 1px solid rgba(var(--base-05), 1);
	color: rgba(var(--base-11), 1);

	* {
		color: inherit;
	}

	&.u-text-r,
	p {
		color: rgba(var(--font-supportive), 1);
	}
}
// components/tabs

.tab {
	span {
		color: rgba(var(--base-02), 1);
	}

	svg {
		fill: rgba(var(--base-02), 1);
	}

	[aria-selected='true'] {
		span {
			color: rgba(var(--primary-accent-yellow), 1);
		}

		svg {
			fill: rgba(var(--primary-accent-yellow), 1);
		}
	}
}

// components/winners
// layouts/account-settings
// layouts/bundles
// layouts/bonus terms & condition

.bonus-terms-wrapper {
	.terms-header__text-color {
		color: rgba(var(--base-02), 1);
	}
}

// layouts/deposit

.deposit-siru {
	.filters {
		input {
			+ .filters__filter {
				background-color: rgba(var(--base-10), 1);
				color: rgba(var(--base-02), 1);
			}

			&:checked {
				+ .filters__filter {
					background-color: rgba(var(--primary-accent-red), 1);
				}
			}
		}
	}
}

// layouts/footer

.paragraph-footer,
.footer-legal-content {
	color: rgba(var(--base-06), 1);

	a {
		color: rgba(var(--primary-accent-yellow), 1);
	}
}

.footer-legal-content small {
	color: rgba(var(--base-06), 1);
}

.other-logos-footer {
	&.not-payment-logos-footer li {
		background-color: transparent;
	}
}

// layouts/info-box

.info-box {
	background-color: rgba(var(--base-02), 1);

	.info-box-text,
	.info-box-value {
		color: rgba(var(--base-09), 1);
	}
}

// layouts/log-in
// layouts/modal-view

.modal-view {
	background-color: rgba(var(--base-01), 1);

	.page-head {
		background-color: inherit;
	}

	&.modal-view--verified {
		background-color: rgba(var(--success), 1);
	}
}

//this is to fix color issue on some of the brands logos displaying wrong color under our cookie-settings head
.cookie-settings-header {
	.svg-logo {
		fill: rgba(var(--primary-accent-red), 1);
	}
}

.missing-data-dialog,
.sportsbook-relieve-limits-dialog,
.time-limits-dialog {
	background-color: rgba(var(--base-01), 1);

	h2,
	p {
		color: var(--main-title);
	}
}

.missing-data-logo > .svg-logo {
	fill: rgba(var(--red-700), 1);
}

.modal-view.modal-view--mobile {
	.page-head {
		background-color: rgba(var(--primary-accent-red), 1);
	}
}

.bankid-modal-container {
	.modal-view {
		background-color: rgba(var(--base-03), 1);
	}
}

section[type='user-checkmark'] {
	svg {
		fill: var(--main-icon);
	}

	h2 {
		color: var(--main-title);
	}

	p {
		color: var(--main-subtitle);
	}
}

// layouts/my-limits

.my-limits-menu {
	header {
		background-color: rgba(var(--base-01), 1);
	}

	.limits-notification-wrapper {
		background-color: rgba(var(--base-02), 1);

		* {
			color: rgba(var(--base-11), 1);
		}

		.limits-notification-icon {
			fill: rgba(var(--base-11), 1);
		}
	}

	.accordion {
		.accordion__link {
			background-color: rgba(var(--base-03), 1);
		}

		.accordion__content {
			background-color: rgba(var(--base-03), 1);
		}
	}
}

// layouts/off-canvas

.global-menu,
.account-menu {
	background-color: rgba(var(--base-11), 1);
}

.global-menu {
	.svg-logo {
		fill: rgba(var(--primary-accent-yellow), 1);
	}
}

.account-menu {
	.page-head {
		svg {
			fill: rgba(var(--base-02), 1);
		}

		.page-head__title {
			color: rgba(var(--base-02), 1);
		}
	}

	.last-login-timer--text,
	.last-login-timer--status {
		color: rgba(var(--font-supportive), 1);
	}

	.list-options {
		background-color: transparent;

		.link-list .link-list__item {
			border-bottom: 1px solid rgba(var(--base-10), 1);
		}

		.link-list__continue-icon {
			fill: rgba(var(--base-01), 1);
		}
	}
}

.notifications-bubbles {
	.link-list__item .link-list__item-link svg:first-child {
		background-color: rgba(var(--base-11), 1) !important;
	}
}
// layouts/off-canvas-account

.user-balance {
	.user-balance__title {
		color: rgba(var(--base-06), 1);
	}

	.user-balance__sum {
		color: rgba(var(--base-02), 1);
	}
}

.session-timer {
	color: rgba(var(--base-04), 1);

	&.session-timer--regulation-header {
		color: rgba(var(--base-12), 1);

		.session-timer-time {
			color: inherit;
		}
	}
}

// layouts/payment
// layouts/pusher
// layouts/recover-password
// layouts/result

[class^='section-head__SubTitle'],
.u-result-header {
	color: rgba(var(--font-supportive), 1);
}

.result {
	&.result--is-open {
		.nfs-bg svg {
			fill: rgba(var(--base-11), 1);
		}

		.failed-deposit {
			*:not(.btn),
			.btn- {
				color: rgba(var(--base-11), 1);
				filter: rgba(var(--base-11), 1);
			}

			svg:not(.logo-payment-method) {
				fill: rgba(var(--base-11), 1);
			}

			.btn--secondary {
				color: rgba(var(--base-11), 1);
			}
		}

		svg {
			fill: rgba(var(--base-02), 1);
		}

		.btn--secondary {
			background-color: rgba(var(--base-11), 0.25);
			color: rgba(var(--base-02), 1);
		}

		.js-try-again-button-header {
			color: rgba(var(--base-11), 1);
		}
	}
}

// layouts/risk

.risk-tag {
	background-color: rgba(var(--base-05), 1);
}

// layouts/shop

.points__svg {
	fill: rgba(var(--primary-accent-red), 1);

	text {
		fill: rgba(var(--base-02), 1);
	}
}

// layouts/signup

.bottom-wrapper {
	background-color: rgba(var(--base-11), 1);

	&.signup--open,
	&.log-in--open {
		background-color: rgba(var(--base-01), 1);
	}
}

// layouts/sportsbook
// layouts/static-text

.wrapper-about,
.wrapper-responsible-gaming,
.wrapper-privacy_policy,
.wrapper-terms-and-conditions,
.wrapper-cookie-policy {
	background-color: rgba(var(--primary-accent-white), 1);

	.adaptable-header--pinned {
		--page-head-title: transparent;
		--page-head-link: rgba(var(--base-10), 1);
		--page-head-icon: rgba(var(--base-10), 1);

		background-color: rgba(var(--primary-accent-white), 1);
	}
}

.static-text.terms-conditions {
	.page-head {
		background-color: rgba(var(--primary-accent-red), 1);

		&.u-bg-trans {
			background-color: var(--sidebar-bg);
		}
	}
}

.wrapper-about {
	.svg-logo {
		fill: rgba(var(--red-700), 1);
	}
}

// layouts/titles

.disclaimer-text {
	color: rgba(var(--primary-accent-red), 1);
	text-decoration: underline;
}

// layouts/transaction-history

.transaction-history-inactive-layer {
	background-color: rgba(var(--base-11), 1);
}

.transaction-modal {
	background-color: rgba(var(--base-01), 1);
}

// layouts/upload

.template-document-wrapper {
	border-bottom: 1px solid rgba(var(--base-05), 1);

	&::after {
		background-color: rgba(var(--primary-accent-red), 1);
	}
}

// layouts/welcome-screen
.welcome-screen-collapse {
	.welcome-screen__summa-icon {
		color: rgba(var(--base-02), 1);
		fill: rgba(var(--base-02), 1);
	}
}

.welcome-menu {
	background-color: rgba(var(--red-900), 1);

	.accordion {
		.accordion__link,
		.accordion__content {
			background-color: rgba(var(--red-700), 1);
			color: rgba(var(--base-02), 1);

			td {
				color: rgba(var(--base-02), 1);
			}

			tr {
				border-bottom: 1px solid rgba(var(--red-900), 1);
				color: rgba(var(--base-02), 1);
			}
		}

		.accordion__link {
			&::before,
			&::after {
				background-color: rgba(var(--base-02), 1);
			}
		}
	}

	.link-list {
		.link-list__item {
			background-color: rgba(var(--red-700), 1);
			color: rgba(var(--base-02), 1);
		}
	}
}

// layouts/withdrawal

.pending-withdrawal {
	border-bottom: 1px solid rgba(var(--base-05), 1);

	.pending-withdrawal__action {
		.pending-withdrawal__cancel-icon {
			fill: rgba(var(--base-06), 1);
		}
	}
}

// layouts/SEO content

.seo-content {
	.seo-content__color,
	* {
		color: rgba(var(--base-02), 1);
	}

	a {
		color: rgba(var(--primary-accent-yellow), 1);
	}
}

// layout account icon
.account-icon-header {
	color: rgba(var(--base-02), 1);
}

// layouts/gaming-habits

.gaming-habits,
.playing-behaviour {
	.page-head {
		svg {
			fill: rgba(var(--base-11), 1);
		}
	}
}

// utils/placeholders
// utils/utility-classes

.u-box-bg {
	background-color: rgba(var(--base-01), 1);
}

.u-text-grey {
	color: rgba(var(--base-06), 1);
}

.u-divider {
	border-color: rgba(var(--base-05), 1);
}

//support/zendesk

.support-button {
	background-color: rgba(var(--primary-accent-red), 1);
	color: rgba(var(--primary-accent-grey-light), 1);

	.support-icon-wrapper,
	.support-text {
		color: rgba(var(--primary-accent-grey-light), 1);
	}
}

// components/payment/link-list
// Payment-provider tags

.payment-tag {
	&__popular {
		background-color: rgba(var(--primary-accent-red), 1);
		color: rgba(var(--font-brand-02), 1);
	}

	&__no-fee {
		background-color: rgba(var(--primary-accent-yellow), 1);
		color: rgba(var(--font-dark), 1);
	}
}

// Transaction History
.selected-filter-tags {
	span.selected-filter-tags_text {
		color: rgba(var(--filtering-pill-font-color-dark), 1) !important;
	}

	button::after {
		color: rgba(var(--filtering-pill-font-color-dark), 1) !important;
	}
}

.pill.disable span {
	color: rgba(var(--filtering-pill-font-color-dark), 1) !important;
}

.border-social-media {
	border: 1px solid rgba(var(--social-media-button-background), 1);
}
